<template>
  <div id="test-page">
    
    <AttributeGroupContainer 
      :group_ids="group_ids"
      :page_index="answer_page_index"
      @selected="answer_selected"
    />

  </div>
</template>

<style lang="scss" scoped>

</style>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';

import AttributeGroupContainer from '@/view/components/attribute_forms/AttributeGroupContainer.vue';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'TestPage',
  mixins: [ toasts ],
  components: {
    AttributeGroupContainer
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods'])
  },
  mounted() {
    
  },
  data() {
    return {
      property_group_answers: [], // { group_id: number, properties: { prop_id: number, text: string | undefined } []}

      group_ids: [4],

      answer_page_index: 0
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        
      }
    },
    currentPeriodId(newValue, oldValue) {
      if (this.currentCompanyId) {

      }
    }
  },
  methods: {
    answer_selected(group_with_answer) {
      console.log('answer for group selected', group_with_answer)
      
      const found = this.property_group_answers.find(item => item.group_id === group_with_answer.group_id);

      if (!found) {
        this.property_group_answers.push(group_with_answer);
      }
      else {
        found.selected_attributes = group_with_answer.selected_attributes;
      }
    },
  }
};
</script>
